import Splide, { Options } from '@splidejs/splide';
import { Grid } from '@splidejs/splide-extension-grid';
import { PaginationPlugin } from '../carousel';

declare let splideoptions: Options;

const createDefaultOptions = () : Options => {

  return {
    paginationArrows: true,
    gap: 10,
    grid: {
      cols: 2,
      rows: 4,
      gap: {
        col: 94,
      },
    },
    breakpoints: {
      767: {
        focus: 'center',
        trimSpace: false,
        perPage: 1.2,
        grid: {
          cols: 1,
        },
      },
    },
  } as Options;
};

export function initDrugList(rootEl: HTMLElement) {
  try {
    let splide: Splide;
    if (typeof splideoptions == 'undefined') {
      splide = new Splide(rootEl, createDefaultOptions());
    } else {
      splide = new Splide(rootEl, splideoptions);
    }

    splide.mount({ Grid, PaginationPlugin });

  } catch (e) {
    console.error(e);
  }
}

