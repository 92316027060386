function getBritishTime() {
  const date = new Date();

  // Define options for formatting
  const options = {
    timeZone: 'Europe/London',
    hour12: false,
    timeZoneName: 'short'
  };

  // Format the date using the options
  const formattedDate = date.toLocaleString('en-GB', options);

  // Check if the formatted date includes 'BST'
  const isBST = formattedDate.includes('BST');

  return { date: date, isBST: isBST };
}

function parseBritishTime(inputTime) {
  // Get the current date
  const currentDate = new Date();

  // Extract the year, month, and day from the current date
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();
  const day = currentDate.getDate();

  // Split the input time string into hours and minutes
  const [hours, minutes] = inputTime.split(':');

  // Create a new Date object with the current year, month, day, hours, and minutes
  const britishTime = new Date(year, month, day, hours, minutes);

  // Define options for formatting
  const options = {
    timeZone: 'Europe/London',
    hour12: false,
    hour: '2-digit',
    minute: '2-digit'
  };

  // Format the date using the options
  //const formattedTime = britishTime.toLocaleString('en-GB', options);
  return britishTime;
}

function add24Hours(date) {
  const newDate = new Date(date);
  newDate.setHours(newDate.getHours() + 24);
  return newDate;
}


var bannerParametersUrl = apiUrl + 'content/web/banners';

let bannerTemplateNoLink = `<div class="cmsBanner" style="%style%" id="cmsBanner%id%">
      <div class="cmsBannerImage" style="display:%icondisplay%">
        %icon%
      </div>
      <div class="cmsBannerText">
        %content%
      </div>
    </div>`;

let bannerTemplateLink = `<a href="%url%" class="cmsBannerAnchor">${bannerTemplateNoLink}</a>`;


let banners = "";
let tAndCContent = "";

fetch(bannerParametersUrl)
  .then(response => {
    return response.json();
  }).then((data) => {

    for (let i = 1; i < 99; i++) {

      if (data[`banner${i}-visible`]) {
        //create banner

        let timeOnlyRegex = new RegExp("^([0-9]*):([0-9]*)");

        let starttime = data[`banner${i}-starttime`];
        let endtime = data[`banner${i}-endtime`];

        let show = true;

        if (timeOnlyRegex.test(starttime) && timeOnlyRegex.test(endtime)) {
          //time only
          let parsedStartTime = parseBritishTime(starttime);
          let parsedEndTime = parseBritishTime(endtime);

          if (parsedStartTime > parsedEndTime) {
            parsedEndTime = add24Hours(parsedEndTime);
          }

          if ((parsedStartTime < Date.now()) && (Date.now() < parsedEndTime)) {
            show = true;
          }
          else {
            show = false;
          }

        }
        else {
          if (starttime) {
            //if now is less than start time continue loop
            let parsedStartTime = Date.parse(starttime);
            if (Date.now() < parsedStartTime)
              continue;
          }

          if (endtime) {
            //if now is greater than end time continue loop
            let parsedEndTime = Date.parse(endtime);
            if (Date.now() > parsedEndTime)
              continue;
          }
        }

        if (show === false)
          continue;

        let urls = data[`banner${i}-urls`];
        if (urls) {
          let splitUrls = urls.split(',');
          let found = false;

          let currentUrl = window.location.pathname;
          for (let url of splitUrls) {
            let regex = new RegExp(url);

            if (regex.test(currentUrl)) {
              found = true;
              break;
            }
          }

          if (!found)
            continue;
        }

        let template = "";
        let url = data[`banner${i}-linkurl`];
        let content = data[`banner${i}-content`];
        let icon = data[`banner${i}-icon`];
        let backgroundcolour = data[`banner${i}-backgroundcolour`];
        let textcolour = data[`banner${i}-textcolour`];
        let tandctext = data[`banner${i}-termsandconditions`];

        template = template.replaceAll("%id%", `${i}`);

        if (url) {
          template = bannerTemplateLink.replaceAll("%url%", url);
        } else
          template = bannerTemplateNoLink;

        //set banner content
        template = template.replaceAll("%content%", content);

        // icon
        if (icon) {
          template = template.replaceAll("%icondisplay%", "inline");
          template = template.replaceAll("%icon%", `<img src="https://assets.lemonaidhealth.co.uk/web/brochure/images/${icon}.svg"/>` );
        } else {
          template = template.replaceAll("%icondisplay%", "none");
          template = template.replaceAll("%icon%", "" );
        }

        // style
        let style = "";
        if (backgroundcolour)
          style += `background:${backgroundcolour};`;

        if (textcolour)
          style += `color:${textcolour};`;

        template = template.replaceAll("%style%", style);

        //append to banner container
        banners += template;

        if (tandctext)
          tAndCContent += tandctext;
      }
    }

    document.getElementById("banner-container").innerHTML = banners;


    if (tAndCContent !== "") {
      let tAndCElement = document.getElementById("banner-terms-and-conditions");
      tAndCElement.innerHTML = tAndCContent;
      tAndCElement.style.display = "block";
    }

    //Webinar banner
    let webinarBanner = document.getElementsByClassName("weight-loss-banner")[0];
    if (webinarBanner && data["show-webinar-banner"])
      webinarBanner.style.display = "block";

    //10WWLP banner
    let wwlpBanner = document.getElementById("weightLossProgrammeLink");
    if (wwlpBanner && data["show-10wwlp-banner"])
      wwlpBanner.style.display = "flex";
  });
