import _ from 'lodash';



var host = window.location.host;
var clinicUrl;
var tracking_url;
var tracking_api_key;
var cookies_domain;

var xServiceCode;
var xClientName;
var defaultUrl = true;

// BOOTS AND LEMONAID URLS (FOR DEVELOPERS USING EC2 / FOR LOCAL DEPLOYMENT & SELF TESTING)
Object.values(window.config).forEach(group => {
  Object.values(group).forEach(item => {
    if (host == item.host) {
      createVariable(item);

      defaultUrl = false;
    }
  });
});

if (defaultUrl) {
  xServiceCode = window.config.dev["dev-brochure"].xServiceCode;
  xClientName = window.config.dev["dev-brochure"].xClientName;
  window.apiUrl = window.config.dev["dev-brochure"].apiUrl;
  clinicUrl = window.config.dev["dev-brochure"].clinicUrl;
  tracking_url = window.config.dev["dev-brochure"].tracking_url;
  tracking_api_key = window.config.dev["dev-brochure"].tracking_api_key;
  cookies_domain = window.config.dev["dev-brochure"].cookies_domain;
}

function createVariable(data) {
  xServiceCode = data.xServiceCode;
  xClientName = data.xClientName;
  window.apiUrl = data.apiUrl;
  clinicUrl = data.clinicUrl;
  tracking_url = data.tracking_url;
  tracking_api_key = data.tracking_api_key;
  cookies_domain = data.cookies_domain;
}

//******** ENDPOINTS *********//
var brochureParameters = apiUrl + 'content/web/brochureParameters';

//******** DISABLE GET STARTED BUTTONS *********//

window.addEventListener('DOMContentLoaded', function () {

  var request = new XMLHttpRequest();
  request.open('GET', brochureParameters, true);
  request.setRequestHeader("Content-Type", 'application/json');
  request.setRequestHeader("Accept", 'application/json');

  request.onload = function (data) {
    var result = JSON.parse(data.target.response);

    if (this.status >= 200 && this.status < 400) {

      // console.log('brochureParameters - success: ', result);

      var pathname = window.location.pathname;

      // console.log('pathname: ', pathname);

      if (result && result.brochure_urls && result.brochure_urls.length) {

        var elem = result.brochure_urls.find(({ url_path }) => url_path === pathname);

        if (elem && elem.get_started_disabled) {

          // console.log('disabling Get Started buttons...');

          document.querySelectorAll(".dynamic-url").forEach((el) => {
            el.classList.add('button-disabled');
            el.removeAttribute("href");
          });
        }

        if (elem && elem.top_banner_1_enabled) {

          document.querySelectorAll(".out-of-stock-banner").forEach((el) => {
            el.classList.remove('hidden');
          });
        }

        if (elem && elem.top_banner_1_text) {

          var bannerText = document.getElementById('out-of-stock-banner-text');
          bannerText.innerHTML += elem.top_banner_1_text;
        }
      }

      // Royal Mail banners
      if (result && result.enableRoyalMailBanner) {
        var element = document.getElementById("royalMailBannerContainer");
        if (element)
          element.style.display = "block";

        var element2 = document.getElementById("royalMailPickupAndDeliverySection");
        if (element2)
          element2.style.display = "block";
      }

      if (result && result.royalMailPickupAndDeliveryContent) {
        var element = document.getElementById("royalMailPickupAndDeliveryContent");
        if (element)
          element.innerHTML = result.royalMailPickupAndDeliveryContent;
      }

      if (result && result.royalMailBannerText) {
        var element = document.getElementById("royalMailBannerText");
        if (element)
          element.innerHTML = result.royalMailBannerText;
      }

      if (result && result.hairLossBannerText) {
        var element = document.getElementById("hair-loss-banner");
        if (element) {
          element.innerHTML = result.hairLossBannerText;
          if (result.hairLossBannerText != "")
            element.style.display = "block";
        }
      }

      if (result && result.hairLossBannerTextColour) {
        var element = document.getElementById("hair-loss-banner");
        if (element)
          element.style.color = result.hairLossBannerTextColour;
      }

      if (result && result.hairLossBannerBackgroundColour) {
        var element = document.getElementById("hair-loss-banner");
        if (element)
          element.style.backgroundColor = result.hairLossBannerBackgroundColour;
      }
      // if (result && result.disableBlackFridayBanner){
      //   var element = document.getElementById("blackFridayBannerContainer");
      //   element.style.display = "none";
      // }
    } else {
      console.log('brochureParameters - error: ', data);
    }
  };

  request.send();
});
